import { gql } from '@apollo/client';

export const BOOKING_FIELDS_FRAGMENT = gql`
  fragment BookingFields on Booking {
    _id
    _partition
    createdAt
    modifiedAt
    createdBy
    modifiedBy
  }
`;

export const BOOKING_TEMPLATE_FIELDS_FRAGMENT = gql`
  fragment BookingTemplateFields on BookingTemplate {
    _id
    booking: bookingId {
      _id
    }
    type
    fields {
      fieldId
      translations {
        label
        language
        value
      }
      radioValues {
        itemId
        label
        language
      }
      selectValues {
        itemId
        label
        language
      }
      type
      system
      required
    }
  }
`;

export const GET_BOOKINGS = gql`
  ${BOOKING_FIELDS_FRAGMENT}
  query bookings($query: BookingQueryInput!, $limit: Int = 0, $sortBy: BookingSortByInput) {
    bookings(query: $query, sortBy: $sortBy, limit: $limit) {
      ...BookingFields
    }
  }
`;

export const GET_BOOKINGS_LISTING = gql`
  query bookings($query: BookingQueryInput!, $limit: Int = 0, $sortBy: BookingSortByInput) {
    bookings(query: $query, sortBy: $sortBy, limit: $limit) {
      _id
      createdAt
      modifiedAt
      createdBy
      modifiedBy
    }
  }
`;

export const GET_BOOKING = gql`
  ${BOOKING_FIELDS_FRAGMENT}
  query booking($query: BookingQueryInput!) {
    booking(query: $query) {
      ...BookingFields
    }
  }
`;

export const GET_BOOKING_MIMINAL_WITH_TIMESLOTS_IDS = gql`
  query booking($query: BookingQueryInput!) {
    booking(query: $query) {
      registrationPageCustomNoticeEnabled
      registrationPagePrivacyNoticeEnabled
      timeslotsDefaultMaxGuests
      timeslotsDuration
      timeslotsHourFrom
      timeslotsHourTo
      _id
      _partition
      timeslots {
        _id
      }
    }
  }
`;

export const BOOKING_STAFF_FRAGMENT = gql`
  fragment BookingStaffFields on BookingStaff {
    _id
    bookable
    color
    externalUserId
    role
    notify
    useBusinessHours
    workingHours {
      weekday
      timeslots {
        hourFrom
        hourTo
      }
    }
  }
`;

export const GET_BOOKING_STAFF_MEMBERS = gql`
  ${BOOKING_STAFF_FRAGMENT}
  query bookingStaffs($query: BookingStaffQueryInput!, $sortBy: BookingStaffSortByInput, $limit: Int = 0) {
    bookingStaffs(query: $query, sortBy: $sortBy, limit: $limit) {
      ...BookingStaffFields
    }
  }
`;

export const BOOKING_CATEGORY_FRAGMENT = gql`
  fragment BookingCategoryFields on BookingCategory {
    _id
    _partition
    internalId
    displayName
    description
    isHiddenFromCustomers
    defaultDurationMinutes
    iconName
    staffMembers {
      _id
    }
    staffMembersMode
    createdAt
    createdBy
  }
`;

export const GET_BOOKING_CATEGORIES = gql`
  query bookingCategories($query: BookingCategoryQueryInput!, $sortBy: BookingCategorySortByInput, $limit: Int = 0) {
    bookingCategories(query: $query, sortBy: $sortBy, limit: $limit) {
      _id
      _partition
      internalId
      displayName
      defaultDurationMinutes
      iconName
    }
  }
`;

export const GET_BOOKING_CATEGORY = gql`
  ${BOOKING_CATEGORY_FRAGMENT}
  query bookingCategory($query: BookingCategoryQueryInput!) {
    bookingCategory(query: $query) {
      ...BookingCategoryFields
    }
  }
`;

export const CREATE_OR_UPDATE_BOOKING_CATEGORY = gql`
  ${BOOKING_CATEGORY_FRAGMENT}
  mutation upsertOneBookingCategory($query: BookingCategoryQueryInput, $data: BookingCategoryInsertInput!) {
    result: upsertOneBookingCategory(query: $query, data: $data) {
      ...BookingCategoryFields
    }
  }
`;

export const GET_BOOKING_TEMPLATES = gql`
  ${BOOKING_TEMPLATE_FIELDS_FRAGMENT}
  query bookingTemplates($query: BookingTemplateQueryInput!, $sortBy: BookingTemplateSortByInput, $limit: Int = 0) {
    bookingTemplates(query: $query, sortBy: $sortBy, limit: $limit) {
      ...BookingTemplateFields
    }
  }
`;

export const GET_BOOKING_TEMPLATE = gql`
  ${BOOKING_TEMPLATE_FIELDS_FRAGMENT}
  query bookingTemplate($query: BookingTemplateQueryInput!) {
    bookingTemplate(query: $query) {
      ...BookingTemplateFields
    }
  }
`;

export const GET_BOOKING_SETTINGS = gql`
  query bookingSetting($query: BookingSettingQueryInput!) {
    bookingSetting(query: $query) {
      _id
      _partition
      openingTimeRange {
        hourFrom
        hourTo
      }
      businessHours {
        timeslots {
          hourFrom
          hourTo
        }
        weekday
      }
      customBusinessHours {
        timeslots {
          hourFrom
          hourTo
        }
        date
        source
      }
      maximumAdvance
      minimumLeadTime
      timeslotsDefaultDuration
      timeslotsDefaultGap
      timeslotsMaxAdditionalGuests
      timeslotsMaxCapacity
      timeslotsMaxSimultaneous
      minHoursRequiredPriorToCancelling
      languages
      enableQrCode
      enableWallet
      enableAdditionalGuests
      enableEmailNotification
      enableCapacityRestriction
      enableClientAppointmentInSharedCalendar
      enableDynamicTimeslots
      enableFixedTimeslots
      enableMarketingReachability
      disallowGuestChangeBooking
      disallowGuestCancelBooking
    }
  }
`;

export const GET_BOOKING_TIMESLOT = gql`
  query bookingTimeslot($query: BookingTimeslotQueryInput!) {
    bookingTimeslot(query: $query) {
      _id
      date
      hourFrom
      hourTo
      maxGuests
      invites: inviteIds {
        _id
        _partition
        isNoShow
        additionalGuests: additionalGuestIds {
          _id
        }
        bookingCustomFields {
          field
          value
        }
        confirmationEmailResendAt
        guest: guestId {
          _id
          crmId
          firstName
          lastName
          email
          phone
          title
          language
        }
        createdBy
        createdAt
        modifiedBy
        modifiedAt
        session: sessionId {
          _id
        }
        timeslot: timeslotId {
          _id
          date
          hourFrom
          hourTo
          fromVirtual
          maxGuests
          staffAssignment {
            inviteId
            staffId
          }
        }
      }
      guestsCount
      additionalGuestsCount
      booking: bookingId {
        _id
      }
      category: categoryId {
        _id
        internalId
        displayName
        iconName
      }
      staffAssignment {
        inviteId
        staffId
      }
      fromVirtual
    }
  }
`;

export const GET_BOOKING_TIMESLOTS_FOR_CALENDAR = gql`
  query bookingTimeslotsForCalendar($input: GetBookingTimeslotsQueryResolverInput!) {
    getBookingTimeslotsQuery(input: $input) {
      _id
      date
      hourFrom
      hourTo
      maxGuests
      guestsCount
      additionalGuestsCount
      category: categoryId {
        _id
        internalId
        displayName
        iconName
      }
      invites: inviteIds {
        _id
        isNoShow
        guest: guestId {
          _id
        }
        createdBy
        timeslot: timeslotId {
          _id
        }
      }
      staffAssignment {
        inviteId
        staffId
      }
      fromVirtual
    }
  }
`;

export const UPDATE_BOOKING = gql`
  mutation updateOneBooking($query: BookingQueryInput!, $set: BookingUpdateInput!) {
    result: updateOneBooking(query: $query, set: $set) {
      _id
      createdAt
      modifiedAt
      createdBy
      modifiedBy
    }
  }
`;

export const UPDATE_BOOKING_SETTINGS = gql`
  mutation replaceOneBookingSetting($query: BookingSettingQueryInput!, $data: BookingSettingInsertInput!) {
    result: replaceOneBookingSetting(query: $query, data: $data) {
      _id
    }
  }
`;

export const UPDATE_BOOKING_STAFF = gql`
  ${BOOKING_STAFF_FRAGMENT}
  mutation replaceOneBookingStaff($query: BookingStaffQueryInput!, $data: BookingStaffInsertInput!) {
    result: replaceOneBookingStaff(query: $query, data: $data) {
      ...BookingStaffFields
    }
  }
`;

export const UPDATE_BOOKING_TEMPLATE = gql`
  ${BOOKING_TEMPLATE_FIELDS_FRAGMENT}
  mutation updateOneBookingTemplate($query: BookingTemplateQueryInput!, $set: BookingTemplateUpdateInput!) {
    result: updateOneBookingTemplate(query: $query, set: $set) {
      ...BookingTemplateFields
    }
  }
`;

export const CREATE_BOOKING = gql`
  mutation insertOneBooking($data: BookingInsertInput!) {
    result: insertOneBooking(data: $data) {
      _id
    }
  }
`;

export const DELETE_BOOKINGS = gql`
  mutation deleteManyBookings($query: BookingQueryInput!) {
    deleteManyBookings(query: $query) {
      deletedCount
    }
  }
`;

export const DELETE_BOOKING_TARGETS = gql`
  mutation deleteManyBookingTargets($query: BookingTargetQueryInput!) {
    deleteManyBookingTargets(query: $query) {
      deletedCount
    }
  }
`;

export const DELETE_BOOKING_CATEGORIES = gql`
  mutation deleteManyBookingCategories($query: BookingCategoryQueryInput!) {
    deleteManyBookingCategories(query: $query) {
      deletedCount
    }
  }
`;

export const CREATE_OR_UPDATE_BOOKING_TARGET = gql`
  mutation upsertOneBookingTarget($query: BookingTargetQueryInput, $data: BookingTargetInsertInput!) {
    result: upsertOneBookingTarget(query: $query, data: $data) {
      _id
    }
  }
`;

export const CREATE_BOOKING_TIMESLOTS = gql`
  mutation insertManyBookingTimeslots($data: [BookingTimeslotInsertInput!]!) {
    result: insertManyBookingTimeslots(data: $data) {
      insertedIds
    }
  }
`;

export const UPDATE_BOOKING_TIMESLOT = gql`
  mutation updateOneBookingTimeslot($query: BookingTimeslotQueryInput!, $set: BookingTimeslotUpdateInput!) {
    result: updateOneBookingTimeslot(query: $query, set: $set) {
      _id
    }
  }
`;

export const DELETE_BOOKING_TIMESLOTS = gql`
  mutation deleteManyBookingTimeslots($query: BookingTimeslotQueryInput!) {
    deleteManyBookingTimeslots(query: $query) {
      deletedCount
    }
  }
`;
